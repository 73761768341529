<template>
  <v-bottom-navigation
    v-if="createActionsBar"
    app
    dark
    class="grey darken-2"
  >
    <!--新增第一層節點-->
    <v-btn
      v-if="allowCreateFirstLevel"
      @click="createFirstLevel"
    >
      <span>{{'action.tree_node.create.top_level'| t}}</span>
      <v-icon>fa fa-plus</v-icon>
    </v-btn>

    <v-btn
      @click="$emit('toggleOpen')"
    >
      <span>{{ 'action.toggle.open'| t }}</span>
      <v-icon>fa fa-chevron-circle-up</v-icon>
    </v-btn>

  </v-bottom-navigation>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    treeKey: String,
    openAll: Boolean,
  },
  methods: {
    createFirstLevel() {
      this.treeActions.createChild(this.rootId, {
        title: this.$t('action.tree_node.create.top_level'),
      })
    },
  },
  computed: {
    hasWritePermission() {
      return this.$store.getters[`tree/${this.treeKey}/hasWritePermission`]
    },
    createActionsBar() {
      return this.$store.getters[`tree/${this.treeKey}/createActionsBar`]
    },
    rootId() {
      return this.$store.getters[`tree/${this.treeKey}/rootId`]
    },
    treeActions() {
      return this.$store.getters[`tree/${this.treeKey}/actions`]
    },
    treeTreeConfig() {
      return this.$store.getters[`tree/${this.treeKey}/config`]
    },
    allowCreateFirstLevel() {
      if(!this.hasWritePermission) return false
      if(!this.treeTreeConfig) return true
      if(this.treeTreeConfig.createFirstLevel === false) return false
      return true
    },
  }
}
</script>

<style lang="sass" type="text/sass" scoped></style>